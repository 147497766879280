import React from "react"
import { LoginContext } from "../../context"
import { Button, Center, Form, InputFormat, Space, Stack, Text } from "@x-apps-projetos/design-system"
import _ from "lodash"
import { FormMessageSelector } from "../form-message-selector"

export const FormInputAccessKey = (): JSX.Element => {

    const {
        email,
        error,
        review,
        accessKey,
        countdown,
        validating,
        requesting,
        enabledValidate,
        setInputRef,
        focusNextInput,
        handlePasteAccessKey,
        handleAccessKey,
        handleRequestAccessKey,
        handleValidateAccessKey,
    } = React.useContext(LoginContext)

    

    return (
        <Form onSubmit={handleValidateAccessKey} style={{ width: "100%" }}>
            <Stack xs={{ "direction": "column", "spacing": "24", "justify": "center", align: "center" }}>
                <FormMessageSelector />
                <Space xs={{ "pl": "16", "pr": "16" }}>
                    <Stack
                        xs={{ "direction": "row", "justify": "space-between", spacing: "4" }}
                        sm={{ spacing: "12" }}
                    >
                        {[0, 1, 2, 3, 4, 5].map((cell) => (
                            <InputFormat
                                key={`input-n-${cell}`}
                                mode="none"
                                textSize="21"
                                symmetricSize="42"
                                textAlign="center"
                                paddingLeft={0}
                                paddingRight={0}
                                inputMode="numeric"
                                name={`code${cell}`}
                                value={accessKey[cell] || ""}
                                getInputRef={(ref: HTMLInputElement) => setInputRef(cell, ref)}
                                onChange={(value) => {
                                    if (typeof value !== "string") return
                                    handleAccessKey(cell, value)
                                }}
                                onPaste={(e) => handlePasteAccessKey(cell, e.clipboardData.getData('text/plain'))}
                                className="selectall"
                                onKeyUp={(e) => focusNextInput(cell, e)}
                                textColor={(error || _.get(review, "accessKey")) ? "danger" : "grayer"}
                            />
                        ))}
                    </Stack>
                    {_.get(review, "accessKey") && (
                        <Text
                            xs={{ variant: "body_2", color: "danger" }}
                            value={_.get(review, "accessKey") || ''}
                        />
                    )}
                </Space>
                <Center>
                    <Stack xs={{ "direction": "column", align: "center", justify: "center", spacing: "24" }}>
                        <Button
                            zoom={true}
                            wrap={true}
                            center={true}
                            size="small"
                            fill={enabledValidate ? "normal" : "outline"}
                            shadow="dropped"
                            color="secondary"
                            type="submit"
                            state={validating ? "loading" : "idle"}
                            label="Entrar"
                        />
                        {countdown === 0 ? (
                            <Stack xs={{ "direction": "column", "align": "center", "justify": "center", "spacing": "8" }}>
                                <Text
                                    value="Não recebeu o código?"
                                    xs={{ variant: "body_2", color: "dark" }}
                                />
                                <Button
                                    wrap={true}
                                    height={32}
                                    center={true}
                                    size="tiny"
                                    fill="none"
                                    color="muted"
                                    type="button"
                                    shadow="dropped"
                                    label="Reenviar código"
                                    state={requesting ? "loading" : "idle"}
                                    loadingMessage="enviando..."
                                    onClick={() => handleRequestAccessKey({ email })}
                                />
                            </Stack>
                        ) : (
                            <Stack xs={{ "direction": "column", "align": "center", "justify": "center", "spacing": "8" }}>
                                <Text
                                    value="Aguarde para enviar novamente"
                                    xs={{ variant: "body_2", color: "dark" }}
                                />
                                <Button
                                    wrap={true}
                                    center={true}
                                    size="tiny"
                                    fill="none"
                                    type="button"
                                    color="secondary"
                                    state="idle"
                                    height={32}
                                    label={`${countdown}s`}
                                />
                            </Stack>
                        )}

                    </Stack>
                </Center>
            </Stack>
        </Form>
    )
}