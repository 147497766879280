import React from "react"
import produce from "immer"
import { LoginContext } from "./context"
import { useParams } from "react-router-dom"
import { ErrorType } from "../../types/error_type"
import { useApi } from "../../providers/api/context"
import { validate } from "@x-apps-projetos/design-system"
import { useApplication } from "../../providers/application/context"
import { FormRequestAccessKeyType, FormValidateAccessKeyType, LoginControllerProps } from "./type"

export const LoginController = (props: LoginControllerProps): JSX.Element => {

    // hooks
    const api = useApi()
    const { product_slug } = useParams();
    const { handleAuthorizeApplication } = useApplication()

    // refs
    const inputRefs = React.useRef<HTMLInputElement[]>([])
    const setInputRef = (pos: number, ref: any) => inputRefs.current[pos] = ref

    // states
    const [email, setEmail] = React.useState<string>("")
    const [countdown, setCountdown] = React.useState<number>(0)
    const [accessKey, setAccessKey] = React.useState<string[]>([])
    const [requesting, setRequesting] = React.useState<boolean>(false)
    const [validating, setValidating] = React.useState<boolean>(false)
    const [error, setError] = React.useState<ErrorType | undefined>(undefined)
    const [review, setReview] = React.useState<object | undefined>(undefined)
    const [session_hash, setSessionHash] = React.useState<string | undefined>(undefined)

    // memos
    const enabledRequest = React.useMemo(() => email.length > 0, [email])
    const enabledValidate = React.useMemo(() => accessKey.length === 6, [accessKey])

    // functions

    const countdownRetry = () => {
        let counter = 30
        setCountdown(counter)
        const interval = setInterval(() => {
            counter--
            setCountdown(counter)
            if (counter <= 0) clearInterval(interval)
        }, 1000)
    }

    const handleAccessKey = (pos: number, value: string) => {
        setAccessKey(produce(accessKey, draft => {
            const treatedValue = value.replace(/[^0-9]/g, '').split("")
            if (treatedValue.length <= 2) {
                const usedValue = [treatedValue[(inputRefs.current[pos].selectionStart || 1) - 1]]
                usedValue.forEach((char, index) => draft[pos + index] = char)
                draft.splice(6, draft.length)
            }
        }))
    }

    const handlePasteAccessKey = (pos: number, value: string) => {
        setAccessKey(produce(accessKey, draft => {
            const treatedValue = value.replace(/[^0-9]/g, '').split("")
            treatedValue.forEach((char, index) => draft[pos + index] = char)
            draft.splice(6, draft.length)   
        }))
    }

    const focusNextInput = (pos: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        const isNumber = /^[0-9]$/i.test(e.key)
        if (!isNumber || !inputRefs.current[pos + 1]) return false
        inputRefs.current[pos + 1].focus()
    }

    const handleRequestAccessKey = async (data: FormRequestAccessKeyType) => {
        const review = await validate(data, {
            email: {
                "required": "O preenchimento do e-mail é obrigatório",
                "email": "Por favor, insira um endereço de e-mail válido"
            }
        })
        setError(undefined)
        setReview(review)
        if (!!review) return
        setRequesting(true)

        api.resources.authentication().requestSession(data.email)
            .then(session => {
                setSessionHash(session.session_hash)
                setAccessKey([])
                countdownRetry()
            })
            .catch(error => setError(error.response.data))
            .finally(() => setRequesting(false))
    }

    const handleValidateAccessKey = async (data: FormValidateAccessKeyType) => {
        const authorization_code = data.code0 + data.code1 + data.code2 + data.code3 + data.code4 + data.code5
        const review = await validate({ accessKey: authorization_code }, {
            accessKey: {
                "min:6": "Informe todos os 6 caracteres",
                "max:6": "Informe apenas 6 caracteres",
            }
        })
        setError(undefined)
        setReview(review)
        if (!!review || !session_hash) return

        // everything is valid
        // then make authentication request
        setValidating(true)
        api.resources.authentication().authenticate({ session_hash, authorization_code })
            .then(auth => handleAuthorizeApplication(auth, String(product_slug)))
            .catch(error => setError(error.response.data))
            .finally(() => setValidating(false))
    }

    const state = {
        error,
        email,
        review,
        setEmail,
        countdown,
        accessKey,
        requesting,
        validating,
        session_hash,
        enabledRequest,
        enabledValidate,
        setInputRef,
        setAccessKey,
        focusNextInput,
        handleAccessKey,
        handlePasteAccessKey,
        handleRequestAccessKey,
        handleValidateAccessKey
    }

    return (
        <LoginContext.Provider value={state}>
            {props.children}
        </LoginContext.Provider>
    )
}