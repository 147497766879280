import { Spin, Stack, Text } from "@x-apps-projetos/design-system"
import React from "react"
import { PageWrapper } from "../page-wrapper"
import { LoadingProps } from "./type"
export const Loading = (props: LoadingProps): JSX.Element => {
    return (
        <PageWrapper>
            <Stack xs={{ direction: "column", align: "center", spacing: "32", wrap: true }}>
                <Spin />
                <Text
                    value={props.message}
                    xs={{ variant: "h5", color: "primary-light" }}
                />
            </Stack>
        </PageWrapper>
    )
}