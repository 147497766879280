import { Login } from './pages/login';
import { NotFound } from './pages/notfound';
import { Activation } from './pages/activation';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Onboard } from './pages/onboard';
import { useApplication } from './providers/application/context';
import { ErrorPage } from './pages/error';
import { useCacheBuster } from './providers/cachebuster/context';

export const Rotuer = (): JSX.Element => {

  const { error } = useApplication()
  const { state, refreshCacheAndReload } = useCacheBuster()
  
  if (state.loading) return <></>;

  if (!state.loading && !state.isLatestVersion) {
    // You can decide how and when you want to force reload
    refreshCacheAndReload();
    return <></>
  }

  if (error) return <ErrorPage />

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Onboard />} />
        <Route path="login/:product_slug" element={<Login />} />
        <Route path="activate/:token" element={<Activation />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}