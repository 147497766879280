import { Box, Button, Center, Container, Stack, Text } from "@x-apps-projetos/design-system"
import { Link } from "react-router-dom"
import { PageWrapper } from "../../components/page-wrapper"

export const Onboard = () => {
    return (
        <PageWrapper background="primary">
            <Container>
                <Center>
                    <Stack xs={{ "direction": "column", "align": "center", "justify": "center", "spacing": "42" }} >
                        <Box
                            xs={{ width: "160px" }}
                            md={{ width: "auto", height: "auto" }}
                        >
                            <img
                                alt="Logotipo"
                                width="100%"
                                src="/assets/images/logo.svg"
                            />
                        </Box>
                        <Text
                            value="Bem vindo(a) ao autenticador da X-Apps"
                            xs={{ color: "white", "variant": "h5" }}
                        />
                        <Stack
                             xs={{ "direction": "column", "align": "center", "justify": "center", "spacing": "12" }}
                             sm={{ "direction": "row" }}
                        >
                            <Link to="login/br.com.x-apps.chat" style={{ "textDecoration": "none" }}>
                                <Button
                                    label="Acessar Chat"
                                    color="secondary-gradient"
                                    center
                                    wrap
                                />
                            </Link>
                            <Link to="login/br.com.x-apps.squad" style={{ "textDecoration": "none" }}>
                                <Button
                                    label="Acessar DevSquad"
                                    color="secondary-gradient"
                                    center
                                    wrap
                                />
                            </Link>
                        </Stack>
                    </Stack>
                </Center>
            </Container>
        </PageWrapper>
    )
}