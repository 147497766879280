import { Icon, Stack, Text, useTheme } from "@x-apps-projetos/design-system"
import React from "react"
export const FormMessageStandard = (): JSX.Element => {
    const theme = useTheme()
    return (
        <>
            <Stack xs={{ "direction": "column", "align": "center", "spacing": "8" }}>
                <Icon iconName="FiCheckCircle" size={32} color={theme.color.success} />
                <Text
                    value="Código enviado para o seu e-mail!"
                    xs={{ "variant": "subtitle_1", color: "primary-light" }}
                />
            </Stack>
            <Stack xs={{ "direction": "column", "align": "center" }}>
                <Text
                    value="Acesse seu e-mail e insira"
                    xs={{ "variant": "body_1", color: "dark", align: "center" }}
                />
                <Text
                    value="abaixo o código de acesso."
                    xs={{ "variant": "body_1", color: "dark", align: "center" }}
                />
            </Stack>
        </>
    )
}