import { Center, Container, Text } from "@x-apps-projetos/design-system"
import React from "react"
import { PageWrapper } from "../../components/page-wrapper"

export const NotFound = (): JSX.Element => {
    return (
        <PageWrapper background="primary">
            <Container>
                <Center>
                    <Text
                        value="Página não encontrada"
                        xs={{ color: "white", "variant": "h3" }}
                    />
                </Center>
            </Container>
        </PageWrapper>
    )
}