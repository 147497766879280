import React from "react"
import { useApi } from "../api/context"
import { ApplicationContext } from "./context"
import { AuthType } from "../../types/auth_type"
import { ApplicationErrorType, ApplicationProps } from "./types"

export const ApplicationProvider = (props: ApplicationProps) => {

    const api = useApi()
    const [error, setError] = React.useState<ApplicationErrorType|undefined>(undefined)
    const [authorizing, setAuthorizing] = React.useState<boolean>(false)
    
    const handleAuthorizeApplication = (auth: AuthType, productSlug: string) => {
        setAuthorizing(true)
        api.resources.authorization()
            .authorizeApplication(auth.jwt, productSlug)
            .then(response => window.location.href = response.callback_url)
            .catch(error => {
                setAuthorizing(false)
                setError(error.response.data)
            })
    }

    // return state resources
    const state = {
        error,
        setError,
        authorizing,
        handleAuthorizeApplication
    }

    return (
        <ApplicationContext.Provider value={state}>
            {props.children}
        </ApplicationContext.Provider>
    )
}