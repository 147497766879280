import _ from "lodash"
import React from "react"
import { InputImagePlacement } from "./style"
import { PageWrapper } from "../../components/page-wrapper"
import { Box, Button, Center, Col, Container, Form, InputImage, InputPhone, InputText, Row, Space, Stack, Text } from "@x-apps-projetos/design-system"
import { ActivationContext } from "./context"
import { Loading } from "../../components/loading"
import { useApplication } from "../../providers/application/context"

export const ActivationView = (): JSX.Element => {

    const {
        review,
        initials,
        isValidated,
        isActivating,
        validatingInvite,
        handleSubmitForm
    } = React.useContext(ActivationContext)

    const { authorizing } = useApplication()

    if (authorizing) return <Loading message="Acessando o aplicativo..." />
    if (validatingInvite) return <Loading message="validando o convite..." />

    return (
        <PageWrapper background="primary">
            <Container>
                <Row xs={{ "spacing": { "horizontal": "48" } }}>
                    <Col xs={12} md={6}>
                        <Center>
                            <Stack
                                xs={{ direction: "column", align: "center", spacing: "8", wrap: true }}
                                md={{ align: "flex-start" }}
                            >
                                <Box
                                    xs={{ width: "120px" }}
                                    md={{ width: "auto", height: "auto" }}
                                >
                                    <img
                                        alt="Logotipo"
                                        width="100%"
                                        src="/assets/images/logo.svg"
                                    />
                                </Box>
                                <Stack
                                    xs={{ direction: "column", align: "center" }}
                                    md={{ align: "flex-start" }}
                                >
                                    <Text
                                        value="Estamos quase lá"
                                        xs={{ variant: "h4", color: "white" }}
                                        md={{ variant: "h2" }}
                                    />
                                    <Text
                                        value="Preencha algumas informações para criar sua conta."
                                        xs={{ variant: "subtitle_2", align: "center", color: "white" }}
                                        md={{ variant: "subtitle_1", align: "start" }}
                                    />
                                </Stack>
                                <Box
                                    xs={{ "width": "auto", "height": "auto", "display": "none" }}
                                    md={{ "display": "flex" }}
                                >
                                    <img src="/assets/images/onboarding-illustration.svg" alt="Onboarding illustration" />
                                </Box>
                            </Stack>
                        </Center>
                    </Col>
                    <Col xs={12} md={6}>
                        <Space
                            xs={{ mt: "96" }}
                            md={{ mt: "64" }}
                        >
                            <Box
                                xs={{
                                    width: "100%",
                                    height: "auto",
                                    padding: "32",
                                    radius: "large",
                                    shadow: "dropped",
                                    background: "white",
                                }}
                                md={{
                                    width: "380px"
                                }}
                            >
                                <Center>
                                    <Form onSubmit={handleSubmitForm} style={{ width: "100%" }}>
                                        <Stack xs={{
                                            direction: "column",
                                            spacing: "24"
                                        }}>
                                            <Center>
                                                <InputImagePlacement>
                                                    <InputImage
                                                        name="avatar"
                                                        placeholder={initials}
                                                        color={initials ? "secondary-gradient" : "primary-light"}
                                                        label="Foto do perfil (opcional)"
                                                    />
                                                </InputImagePlacement>
                                            </Center>
                                            <InputText
                                                name="name"
                                                mode="none"
                                                label="Nome"
                                                placeholder="Nome"
                                                fullWidth={true}
                                                valid={isValidated}
                                                error={_.get(review, "name")}
                                            />
                                            <InputText
                                                name="last_name"
                                                mode="none"
                                                label="Sobrenome"
                                                placeholder="Sobrenome"
                                                fullWidth={true}
                                                valid={isValidated}
                                                error={_.get(review, "last_name")}
                                            />
                                            <InputPhone
                                                name="phone"
                                                mode="none"
                                                label="Telefone"
                                                placeholder="00 0000 0000"
                                                fullWidth={true}
                                                valid={isValidated}
                                                error={_.get(review, "phone")}
                                            />
                                            <Center>
                                                <Button
                                                    wrap={true}
                                                    label="Entrar"
                                                    fill="outline"
                                                    type="submit"
                                                    state={isActivating ? "loading" : "idle"}
                                                    color="secondary-gradient"
                                                />
                                            </Center>
                                        </Stack>
                                    </Form>
                                </Center>
                            </Box>
                        </Space>
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}