import React from "react"
import { LoginContext } from "./context"
import { PageWrapper } from "../../components/page-wrapper"
import { FormInputEmail } from "./components/form-input-email"
import { FormInputAccessKey } from "./components/form-input-access-key"
import { Box, Center, Col, Container, Row, Space, Stack } from "@x-apps-projetos/design-system"
import { useApplication } from "../../providers/application/context"
import { Loading } from "../../components/loading"

export const LoginView = () => {

    const { authorizing } = useApplication()
    const { session_hash } = React.useContext(LoginContext)
    if (authorizing) return <Loading message="Acessando o aplicativo..." />

    return (
        <PageWrapper background="primary">
            <Container>
                <Row xs={{ "spacing": { "horizontal": "48" } }}>
                    <Col xs={12} md={6}>
                        <Stack
                            xs={{ direction: "column", "align": "center", "justify": "center", "spacing": "8" }}
                            sm={{ direction: "row" }}
                            md={{ direction: "column" }}
                        >
                            <Box
                                xs={{ width: "120px" }}
                                md={{ width: "auto", height: "auto" }}
                            >
                                <img
                                    alt="Logotipo"
                                    width="100%"
                                    src="/assets/images/logo.svg"
                                />
                            </Box>
                            <Box
                                xs={{ "width": "auto", "height": "auto", "display": "none" }}
                                md={{ "display": "flex" }}
                            >
                                <img
                                    alt="Login illustration"
                                    width="100%"
                                    src="/assets/images/login-illustration.svg"
                                />
                            </Box>
                        </Stack>
                    </Col>
                    <Col xs={12} md={6}>
                        <Box
                            xs={{
                                width: "100%",
                                height: "auto",
                                padding: "32",
                                radius: "large",
                                shadow: "dropped",
                                background: "white",
                                marginTop: "60px"
                            }}
                            md={{
                                width: "380px",
                                height: "450px",
                                marginTop: "0px"
                            }}
                        >
                            <Center>
                                <Stack xs={{ "direction": "column", "align": "center" }}>
                                    <Box
                                        xs={{ 'width': "180px", "marginTop": "-100px", "display": "flex" }}
                                        sm={{ "display": "none" }}
                                    >
                                        <img
                                            alt="Login illustration"
                                            width="100%"
                                            src="/assets/images/login-illustration.svg"
                                        />
                                    </Box>
                                    <Space xs={{ "pt": "12", "expand": true }}>

                                        {!!session_hash ? (
                                            <FormInputAccessKey />
                                        ) : (
                                            <FormInputEmail />
                                        )}
                                    </Space>
                                </Stack>
                            </Center>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}