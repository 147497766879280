import { AxiosInstance } from "axios"
import { AuthorizationType } from "../../../types/authorization_type"

export class ApiResourceAuthorization {
    constructor(private api: AxiosInstance) { }

    authorizeApplication = async (jwt: string, applicationSlug: string): Promise<AuthorizationType> => {
        const response = await this.api.get<AuthorizationType>(`/authorize/${applicationSlug}`, { 
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        })
        return response.data
    }

}