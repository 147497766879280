import React from "react"
import axios from "axios"
import { ApiProviderProps } from "./types"
import { ApiProviderContext } from "./context"
import { ApiResourceOnboarding } from "./resources/onboarding"
import { ApiResourceAuthorization } from "./resources/authorization"
import { ApiResourceAuthentication } from "./resources/authentication"

export const ApiProvider = (props: ApiProviderProps) => {

    // import localstorage to get jwt token
    // const localstorage = useLocalStorage()

    // create an api instance from axios
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL || "http://localhost:1337",
    })

    // // intercept all requests to inject token
    // api.interceptors.request.use((config) => {
    //     const jwt = localstorage.get("jwt")
    //     if (jwt) _.set(config, "headers.Authorization", `Bearer ${jwt}`)
    //     return config
    // })

    // define all api resources
    const resources = {
        onboarding: () => new ApiResourceOnboarding(api),
        authorization: () => new ApiResourceAuthorization(api),
        authentication: () => new ApiResourceAuthentication(api)
    }

    // return state resources
    const state = {
        resources
    }

    return (
        <ApiProviderContext.Provider value={state}>
            {props.children}
        </ApiProviderContext.Provider>
    )
}