import React from 'react';
import { CacheBusterController } from './controller';
import { CacheBusterProps } from './types';

export const CacheBusterProvider = (props: CacheBusterProps): JSX.Element => {
    return (
        <CacheBusterController>
            {props.children}
        </CacheBusterController>
    )
}