import React from "react"
import { LoginController } from "./controller"
import { LoginProps } from "./type"
import { LoginView } from "./view"

export const Login = (props: LoginProps): JSX.Element => {
    return (
        <LoginController>
            <LoginView />
        </LoginController>
    )
}