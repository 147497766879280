import styled from "@emotion/styled";
import { Box } from "@x-apps-projetos/design-system";
import { PageWrapperProps } from "./type";

export const PageWrapperStyle = styled(Box)<PageWrapperProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: ${props => props.background ? props.theme.color[props.background] : props.theme.backgroundColor};
`
