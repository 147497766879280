import { Space } from "@x-apps-projetos/design-system"
import React from "react"
import { PageWrapperStyle } from "./style"
import { PageWrapperProps } from "./type"
export const PageWrapper = (props: PageWrapperProps): JSX.Element => {
    return (
        <Space
            xs={{ m: "zero", "expand": true }}
            sm={{ m: "48" }}
        >
            <PageWrapperStyle
                sm={{ radius: "large"}}
                background={props.background}
            >
                {props.children}
            </PageWrapperStyle>
        </Space>
    )
}