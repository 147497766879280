import { AxiosInstance } from "axios"
import { AuthType } from "../../../types/auth_type"
import { SessionType } from "../../../types/session_type"

type AuthenticatePayloadType = {
    session_hash: string
    authorization_code: string
}

export class ApiResourceAuthentication {
    constructor(private api: AxiosInstance) { }

    requestSession = async (email: string): Promise<SessionType> => {
        const response = await this.api.post<SessionType>(`/auth/session`, { email })
        return response.data
    }

    authenticate = async (payload: AuthenticatePayloadType): Promise<AuthType> => {
        const response = await this.api.post<AuthType>(`/auth/authorize`, payload)
        return response.data
    }

}