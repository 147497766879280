import { AxiosInstance } from "axios"
import { AuthType } from "../../../types/auth_type"
import { InviteType } from "../../../types/invite_type"

type AcctivateAccountPayloadType = {
    token: string,
    name: string
    last_name: string
    phone: string
    avatar?: File
}

export class ApiResourceOnboarding {
    constructor(private api: AxiosInstance) { }

    validateInviteToken = async (token: string): Promise<AuthType | InviteType> => {
        const response = await this.api.get<AuthType | InviteType>(`/invites/validation/${token}`)
        return response.data
    }

    activateAccount = async (payload: AcctivateAccountPayloadType): Promise<AuthType> => {
        const formData = new FormData()
        formData.append('name', payload.name)
        formData.append('last_name', payload.last_name)
        formData.append('phone', payload.phone)
        formData.append('token', payload.token)
        if(payload.avatar) formData.append('avatar', payload.avatar)
        const response = await this.api.post<AuthType>(`/auth/registration`, formData)
        return response.data
    }

}