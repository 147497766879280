import React from "react"
import { LoginContext } from "../../context"
import { FormMessageStandard } from "../form-message-standard"
import { FormMessageError } from "../form-message-error"
export const FormMessageSelector = () => {

    const { error } = React.useContext(LoginContext)

    if (error) return <FormMessageError {...error} />

    // default
    return (<FormMessageStandard />)
}