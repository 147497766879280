import React from "react"
import { ErrorType } from "../../../../types/error_type"
import { Icon, Stack, Text, useTheme } from "@x-apps-projetos/design-system"

export const FormMessageError = (props: ErrorType) => {
    const theme = useTheme()
    return (
        <>
            <Stack xs={{ "direction": "column", "align": "center", "spacing": "8" }}>
                <Icon iconName="FiXCircle" size={32} color={theme.color.danger} />
                <Text
                    value={props.title}
                    xs={{ "variant": "subtitle_1", color: "primary-light" }}
                />
            </Stack>
            <Stack xs={{ "direction": "column", "align": "center" }}>
                {/* <Text
                    value={props.message}
                    xs={{ "variant": "body_1", color: "dark", align: "center" }}
                /> */}
                 <Text
                    value={props.solution}
                    xs={{ "variant": "body_1", color: "dark", align: "center" }}
                />
            </Stack>
        </>
    )
}