import _ from "lodash"
import React from "react"
import { LoginContext } from "../../context"
import { Button, Center, Form, InputText, Stack, Text } from "@x-apps-projetos/design-system"

export const FormInputEmail = (): JSX.Element => {

    const {
        error,
        email,
        review,
        setEmail,
        requesting,
        enabledRequest,
        handleRequestAccessKey,
    } = React.useContext(LoginContext)

    return (
        <Form onSubmit={handleRequestAccessKey} style={{ width: "100%" }}>
            <Stack
                xs={{ "direction": "column", "spacing": "24", "justify": "center", align: "center" }}
                sm={{ "align": "flex-start" }}
                md={{ "align": "center" }}
            >
                <Text
                    value="Login"
                    xs={{ "variant": "subtitle_1", color: "primary" }}
                />
                <Stack
                    xs={{ "direction": "column", "align": "center" }}
                    sm={{ align: "flex-start" }}
                    md={{ align: "center" }}
                >
                    <Text
                        value="Informe seu e-mail para"
                        xs={{ "variant": "body_1", color: "dark", align: "center" }}
                    />
                    <Text
                        value="enviarmos seu código de acesso."
                        xs={{ "variant": "body_1", color: "dark", align: "center" }}
                    />
                </Stack>
                <InputText
                    mode="none"
                    name="email"
                    value={email}
                    label="E-mail"
                    fullWidth={true}
                    onChange={setEmail}
                    valid={enabledRequest}
                    placeholder="nome@email.com"
                    error={_.get(review, "email", _.get(error, "message"))}
                />
                <Center>
                    <Button
                        zoom={true}
                        wrap={true}
                        center={true}
                        size="small"
                        fill={enabledRequest ? "normal" : "outline"}
                        shadow="dropped"
                        color="secondary"
                        type="submit"
                        state={requesting ? "loading" : "idle"}
                        label="Enviar código de acesso"
                    />
                </Center>
            </Stack>
        </Form>
    )
}