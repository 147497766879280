import { validate } from "@x-apps-projetos/design-system"
import _ from "lodash"
import React from "react"
import { ActivationContext } from "./context"
import { AuthType } from "../../types/auth_type"
import { useApi } from "../../providers/api/context"
import { useNavigate, useParams } from "react-router-dom"
import { ActivationFormRegistrationType, ActivationControllerProps, ActivationFormReviewType } from "./type"
import { useApplication } from "../../providers/application/context"
export const ActivationController = (props: ActivationControllerProps): JSX.Element => {

    const api = useApi()
    const { token } = useParams();
    const navigate = useNavigate()
    const { handleAuthorizeApplication, setError } = useApplication()
    const [review, setReview] = React.useState<ActivationFormReviewType | undefined>(undefined)
    const [initials, setInitials] = React.useState<string | undefined>(undefined)
    const [isValidated, setValidated] = React.useState<boolean>(false)
    const [isActivating, setActivating] = React.useState<boolean>(false)
    const [validatingInvite, setValidatingInvite] = React.useState<boolean>(true)

    const handleFailureInviteValidation = (error?: any) => {
        if (!error) {
            return navigate("/", { replace: true })
        } else if (error?.response?.data?.redirect) {
            window.location.href = error?.response?.data?.redirect
        } else {
            setError(error?.response?.data || error)
        }
    }

    const handleValidateInvite = () => {
        if (!token) return handleFailureInviteValidation()
        setValidatingInvite(true)
        api.resources.onboarding()
            .validateInviteToken(token)
            .then((response) => {
                setValidatingInvite(false)
                if (_.get(response, "jwt")) {
                    const activation = response as AuthType
                    handleAuthorizeApplication(activation, String(activation.product_slug))
                }
            })
            .catch(handleFailureInviteValidation)
    }

    const handleSubmitForm = async (data: ActivationFormRegistrationType) => {
        const review = await validate(data, {
            name: {
                "required": "Nome obrigatório",
                "min:2": "Deve possuir no mínimo 2 caracteres",
                "max:30": "Deve possuir no máximo 30 caracteres",
                "alpha-accent": "Não pode conter espaços ou números"
            },
            last_name: {
                "required": "Sobrenome obrigatório",
                "min:2": "Deve possuir no mínimo 2 caracteres",
                "max:30": "Deve possuir no máximo 30 caracteres",
                "alpha-accent": "Não pode conter espaços ou números"
            },
            phone: {
                "required": "Telefone obrigatório",
                "e164": "Deve ser um telefone no formato internacional válido"
            }
        })
        setReview(review)
        if (!!review) return

        setInitials(data.name.slice(0, 1) + data.last_name.slice(0, 1))
        setValidated(true)
        setActivating(true)
        api.resources.onboarding()
            .activateAccount({
                "name": data.name,
                "last_name": data.last_name,
                "phone": data.phone,
                "token": String(token),
                "avatar": data.avatar
            })
            .then((response) => {
                handleAuthorizeApplication(response, String(response.product_slug))
            })
            .catch(handleFailureInviteValidation)
            .finally(() => setActivating(false))

    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(handleValidateInvite, [])

    const state = {
        review,
        initials,
        isValidated,
        isActivating,
        validatingInvite,
        handleSubmitForm
    }

    return (
        <ActivationContext.Provider value={state}>
            {props.children}
        </ActivationContext.Provider>
    )

}