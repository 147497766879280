import React from "react"
import { LocalStorageContext } from "./context";
import { LocalStorageProps } from "./types";

export const LocalStorageProvider = (props: LocalStorageProps): JSX.Element => {

    const APPKEY = "@xapps-squad"

    const state = {
        get: (field: string) => {
            return localStorage.getItem(`${APPKEY}/${field}`);
        },
        set: (field: string, value: any) => {
            return localStorage.setItem(`${APPKEY}/${field}`, value);
        },
        remove: (field: string) => {
            return localStorage.removeItem(`${APPKEY}/${field}`)
        }
    }

    return (
        <LocalStorageContext.Provider value={state}>
            {props.children}
        </LocalStorageContext.Provider>
    )
}

