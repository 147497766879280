import { Box, Button, Center, Container, Stack, Text } from "@x-apps-projetos/design-system"
import { PageWrapper } from "../../components/page-wrapper"
import { useApplication } from "../../providers/application/context"

export const ErrorPage = () => {

    const { error } = useApplication()

    return (
        <PageWrapper background="primary">
            <Container>
                <Center>
                    <Stack xs={{ "direction": "column", "align": "center", "justify": "center", "spacing": "42" }} >
                        <Box
                            xs={{ width: "160px" }}
                            md={{ width: "auto", height: "auto" }}
                        >
                            <img
                                alt="Logotipo"
                                width="100%"
                                src="/assets/images/logo.svg"
                            />
                        </Box>
                        <Stack xs={{ "direction": "column", "align": "center", "justify": "center", "spacing": "12" }} >
                            <Text
                                value={error?.title || "Unknown error"}
                                xs={{ color: "white", "variant": "h5" }}
                            />
                            <Text
                                value={error?.message || "Unknown message"}
                                xs={{ color: "white", "variant": "body_1" }}
                            />
                            <Text
                                value={error?.solution || "Unknown resolution"}
                                xs={{ color: "white", "variant": "body_1" }}
                            />
                        </Stack>
                        <Stack
                            xs={{ "direction": "column", "align": "center", "justify": "center", "spacing": "12" }}
                            sm={{ "direction": "row" }}
                        >
                            <Button
                                label="Retornar para o início"
                                color="secondary-gradient"
                                center
                                wrap
                                onClick={() => window.location.href = "/"}
                            />
                        </Stack>
                    </Stack>
                </Center>
            </Container>
        </PageWrapper>
    )
}