import React from 'react';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./index.css"
import ReactDOM from 'react-dom/client';
import { ApiProvider } from './providers/api';
import { ApplicationProvider } from './providers/application';
import { LocalStorageProvider } from './providers/localstorage';
import { ThemeProvider, DEFAULT_THEME } from "@x-apps-projetos/design-system"
import { Rotuer } from './router';
import { CacheBusterProvider } from './providers/cachebuster';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.3,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <CacheBusterProvider>
      <ThemeProvider theme={DEFAULT_THEME} backgroundColor="=#ffffff">
        <LocalStorageProvider>
          <ApiProvider>
            <ApplicationProvider>
              <Rotuer />
            </ApplicationProvider>
          </ApiProvider>
        </LocalStorageProvider>
      </ThemeProvider>
    </CacheBusterProvider>
  </React.StrictMode>
);
