import React from "react"
import { CacheBusterContext } from "./context"
import { CacheBusterControllerProps, CacheBusterStateType } from "./types"
import packageJson from '../../../package.json';
const currentVersion = packageJson.version;

export const CacheBusterController = (props: CacheBusterControllerProps): JSX.Element => {

    const [state, setState] = React.useState<CacheBusterStateType>({ loading: true, isLatestVersion: false, version: currentVersion })

    const refreshCacheAndReload = () => {
        console.log('Clearing cache and hard reloading...')
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
        }
        // delete browser cache and hard reload
        window.location.reload();
    }

    // version from response - first param, local version second param
    const semverGreaterThan = (versionA: string, versionB: string) => {
        const versionsA = versionA.split(/\./g);
        const versionsB = versionB.split(/\./g);
        while (versionsA.length || versionsB.length) {
            const a = Number(versionsA.shift());
            const b = Number(versionsB.shift());
            if (a === b) continue;
            return a > b || isNaN(b);
        }
        return false;
    };

    React.useEffect(() => {
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const shouldForceRefresh = semverGreaterThan(currentVersion, latestVersion);
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    setState({ loading: false, isLatestVersion: false, version: currentVersion });
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    setState({ loading: false, isLatestVersion: true, version: currentVersion });
                }
            });
    }, [])

    const context = {
        state: state,
        refreshCacheAndReload: refreshCacheAndReload
    }

    return (
        <CacheBusterContext.Provider value={context}>
            {props.children}
        </CacheBusterContext.Provider>
    )
}